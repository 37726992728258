<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill-rule="evenodd"
      d="M20.5,9.5 C27.955,9.5 34,13.535 34,18.5 C34,21.02 32.44,23.3 29.935,24.935 C31.54,23.63 32.5,21.98 32.5,20.195 C32.5,15.935 27.13,12.5 20.5,12.5 L20.5,17 L14.5,11 L20.5,5 L20.5,9.5 M20.5,30.5 C13.045,30.5 7,26.465 7,21.5 C7,18.98 8.56,16.7 11.065,15.065 C9.46,16.37 8.5,18.02 8.5,19.82 C8.5,24.065 13.87,27.5 20.5,27.5 L20.5,23 L26.5,29 L20.5,35 L20.5,30.5 Z"
    />
  </svg>
</template>
<script>
import { FREE_SPINS_ICON } from '@/constants'
export default {
  name: FREE_SPINS_ICON,
}
</script>
